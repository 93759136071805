import React from 'react';
import { Modal, Row, Col, Badge } from 'reactstrap';

import '../../assets/css/post-modal.css';

const UserModal = ({ toggleModal, isOpen, user }) => {
  return (
    <>
      <Row>
        <Col md="12">
          <Modal
            backdrop="static"
            className="modal-dialog-centered modal-lg"
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
          >
            <div className="modal-header">
              <h2 className="modal-title m-3" id="modal-title-default">
                User Data
              </h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body post-modal-body m-4">
              <div className="avatar-big rounded-circle mr-4">
                <img
                  alt="..."
                  src={
                    user?.profileImage ? `${process.env.REACT_APP_CDN_URL}/${user?.profileImage}` :
                    require('../../assets/img/theme/user-logo.png')
                      .default
                  }
                />
              </div>
              <div style={{ marginTop: '2rem' }}>
                <p>
                  <h4 style={{display: 'inline'}}>Name: </h4>
                  {user?.name}
                </p>
                <p>
                  <h4 style={{display: 'inline'}}>Email: </h4>
                  {user?.email}
                </p>
                {
                  <p>
                    <h4 style={{display: 'inline'}}>Referral count: </h4>
                  {
                    <Badge
                      color="dark"
                      pill
                      style={{ margin: '2px' }}
                    >
                      {+user?.referredCount}
                    </Badge>
                  }
                  </p>
                }
                {
                  user?.userInfo?.about &&
                  <p>
                    <h4 style={{display: 'inline'}}>About: </h4>{user?.userInfo?.about}
                  </p>
                }
                {
                  user?.roles?.roles.length > 0 && 
                  <p>
                    <h4 style={{display: 'inline'}}>Roles: </h4>
                  {
                    user?.roles?.roles.map((role) => {
                    return <Badge color="success" style={{ margin: '2px' }}> {role} </Badge>;
                    })
                  }
                  </p>
                }
                {
                  user?.userInfo?.skills.length > 0 && 
                  <p>
                    <h4 style={{display: 'inline'}}>Interested in: </h4>
                  {
                    user?.userInfo?.skills.map((skill) => {
                    return <Badge color="success" style={{ margin: '2px' }}> {skill} </Badge>;
                    })
                  }
                  </p>
                }
                {
                  user?.userInfo?.topicsToTeach.length > 0 && 
                  <p>
                    <h4 style={{display: 'inline'}}>Topics to teach: </h4>
                  {
                    user?.userInfo?.topicsToTeach.map((topic) => {
                    return <Badge color="success" style={{ margin: '2px' }}> {topic} </Badge>;
                    })
                  }
                  </p>
                }
                {
                  user?.userInfo?.certificates.length > 0 && 
                  <p>
                    <h4 style={{display: 'inline'}}>Certificates: </h4>
                  {
                    user?.userInfo?.certificates.map((cert) => {
                    return <Badge color="success" style={{ margin: '2px' }}> {cert} </Badge>;
                    })
                  }
                  </p>
                }
                {
                  user?.userInfo?.educationQualification.length > 0 && 
                  <p>
                    <h4 style={{display: 'inline'}}>Proud of: </h4>
                  {
                    user?.userInfo?.educationQualification.map((edu) => {
                    return <Badge color="success" style={{ margin: '2px' }}> {edu} </Badge>;
                    })
                  }
                  </p>
                }
                {
                  user?.userInfo?.location &&
                  <p>
                    <h4 style={{display: 'inline'}}>Location: </h4>{user?.userInfo?.location}
                  </p>
                }
              </div>
            </div>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default UserModal;
